import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FadeInSection from "./FadeInSection";
import Poster from "../../img/poster.png";

const isHorizontal = window.innerWidth < 600;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  if (isHorizontal) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  } else {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function panel12Props(index) {
  if (isHorizontal) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`
    };
  } else {
    return {
      id: `vertical-tab-${index}`
    };
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
    display: "flex",
    height: 300
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}));

const JobList = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const experienceItems = {
    "Roku Inc.": {
        jobTitle: "Software Engineer @",
        duration: "May 2023 - Present",
        companyLink: "https://www.roku.com/",
        desc: [
            "Working on Developer Tools and Platform Infrastructure Engineering.",
        ],
        previousRoles: [
            {
            jobTitle: "Software Engineer Intern",
            duration: "June 2022 - September 2022",
            desc: [
                "Created Grafana dashboards with PromQL, enhancing Jenkins services monitoring and sending alerts to Slack.",
                "Provisioned and streamlined dashboard management and service data scraper using Kubernetes, Gitlab CI, AWS, Bash, Terraform and Python.",
            ]
            }
        ]
    },
    "iMaPLe Lab": {
        jobTitle: "Research Assistant, AI/ML @",
        duration: "September 2022 - Present",
        companyLink: "https://research.coe.drexel.edu/ece/imaple/",
        desc: [
            "Researching Machine Learning for Audio, Acoustic, Speech, and Signal Processing.",
        ]
    },
    "Moberg Analytics": {
        jobTitle: "Software Engineer @",
        duration: "September 2022 - June 2023",
        companyLink: "https://moberganalytics.com/",
        desc: [
            "Worked on the CONNECT App, a cloud-based application within the AI ecosystem platform for neurocritical care.",
            "Developed core back-end paginated APIs, created reusable and customizable front-end layouts with dynamic components using React.js"
        ],
        previousRoles: [
            {
            jobTitle: "Software Engineer Co-op",
            duration: "March 2022 - June 2022",
            desc: [
                "Designed a patient de-identification tool using Python, Bash, and IBM Cloud.",
                "Developed a Python HDF5-file converter that increased the supported data types from 1 to 5.",
                <>
                    Co-authored an{" "}
                    <span
                    onClick={handleOpen}
                    style={{
                        cursor: "pointer",
                        color: "#4f46e5",
                        textDecoration: "underline",
                    }}
                    >
                    abstract poster
                    </span>{" "}
                    on the converter and de-identification tool used in UCSF clinical trials.
                </>,
            ]
            }
        ]
    },
    "Bristol Myers Squibb": {
        jobTitle: "Cloud Developer Co-op @",
        duration: "March 2021 - September 2021",
        companyLink: "https://www.bms.com/",
        desc: [
            "Developed an application using React, Node.js, SCIM, LDAP, and AWS to fully automate the licensing management efforts.",
            "Deployed and documented the full cycle software with the ability to scale and reproduce using Infrastructure as Code.",
            "Integrated software application RestAPIs security with AWS API Gateway and SSO Authentication.",
        ]
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation={!isHorizontal ? "vertical" : null}
        variant={isHorizontal ? "fullWidth" : "scrollable"}
        value={value}
        onChange={handleChange}
        className={classes.tabs}
      >
        {Object.keys(experienceItems).map((key, i) => (
          <Tab label={isHorizontal ? `x[${i}]` : key} {...panel12Props(i)} />
        ))}
      </Tabs>
      {Object.keys(experienceItems).map((key, i) => (
        <TabPanel value={value} index={i} key={i}>
            <span className="joblist-job-title">
            {experienceItems[key]["jobTitle"] + " "}
            </span>
            <span className="joblist-job-company"><a href={experienceItems[key]["companyLink"]} target="_blank" rel="noopener noreferrer">{key}</a></span>
            <div className="joblist-duration">
            {experienceItems[key]["duration"]}
            </div>
            <ul className="job-description">
            {experienceItems[key]["desc"].map((descItem, i) => (
                <FadeInSection delay={`${i + 1}00ms`} key={i}>
                <li>{descItem}</li>
                </FadeInSection>
            ))}
            </ul>
            {experienceItems[key]["previousRoles"] &&
            experienceItems[key]["previousRoles"].map((role, j) => (
                <div key={j}>
                <span className="joblist-job-title">
                    {role.jobTitle + " "}
                </span>
                <div className="joblist-duration">{role.duration}</div>
                <ul className="job-description">
                    {role.desc.map((descItem, k) => (
                    <FadeInSection delay={`${k + 1}00ms`} key={k}>
                        <li>{descItem}</li>
                    </FadeInSection>
                    ))}
                </ul>
                </div>
            ))}
        </TabPanel>
        ))}
        {/* Modal for the poster */}
      <Modal open={open} onClose={handleClose}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
          }}
        >
          <img
            src={Poster}
            alt="Abstract Poster"
            style={{ maxWidth: "100%", maxHeight: "80vh" }}
          />
          <button onClick={handleClose} style={{ marginTop: "10px", cursor: "pointer" }}>
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default JobList;
