import { FaCoffee } from "react-icons/fa";

const DownloadButton = () => {
    return (
        <button className="relative flex items-center gap-2 px-6 py-3 text-[#FFFF00] bg-transparent border border-[#FFFF00] rounded-full shadow-[0_0_15px_rgba(255,255,0,0.5)] hover:shadow-[0_0_25px_rgba(255,255,0,0.8)] transition-shadow duration-300">
            <FaCoffee className="text-2xl" />
            <a href="https://calendly.com/qtvo93/coffee-chat" target="_blank" rel="noopener noreferrer">Coffee Chat with Me </a>
        </button>
    );
};

export default DownloadButton;
