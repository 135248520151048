import { FaUserCircle, FaUserGraduate,FaPencilRuler,FaArrowUp } from 'react-icons/fa';
import { MdWork, MdClose } from 'react-icons/md';
import { FaBars } from "react-icons/fa";
import { useEffect, useState, useRef } from "react";

const SideBar = (props) => {

    const [showSidebar, setShowSidebar] = useState(false)
    let sideBarRef = useRef()

    useEffect(() => {
      if ([null, undefined].includes(sideBarRef.current)) return
      let handler = (event) => {
        if (!sideBarRef.current.contains(event.target)) {
          setShowSidebar(false)
        }}
      document.addEventListener("mousedown", handler);
      return () => {
        document.removeEventListener("mousedown", handler)
      }
    })


    const handleClickSection = (ref) => {
      ref.current?.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
    }

    return (
    <div className="z-[9999999]"> 
    <div ref={sideBarRef}>
      {showSidebar ? (
        <button
          className="fixed z-[9999999] flex items-center cursor-pointer right-5 top-4 mr-3 ml-1 md:right-5 md:ml-6 md:top-4 hover:text-white custom-text-color hover:animate-pulse"
          onClick={() => setShowSidebar(!showSidebar)}
        >
          <MdClose size={30} />
        </button>
      ) : (

        <button
            className="fixed z-[9999999] flex items-center cursor-pointer right-5 top-4 mr-3 ml-1 md:right-5 md:ml-6 md:top-4 hover:text-white custom-text-color hover:animate-pulse"
            onClick={() => setShowSidebar(!showSidebar)}
        >
            <FaBars size={28} />
        </button>

      )}
  
        
    <div className={`fixed top-0 left-0 h-screen w-20 flex flex-col pt-10
    bg-black bg-opacity-20 dark:bg-accent dark:bg-opacity-70 shadow-lg z-[9999999]  ease-in-out duration-300 ${
      showSidebar ? "translate-x-0 " : "-translate-x-20"}`}>

        <button type="submit"  onClick={() => {handleClickSection(props.introRef);  }}>      
        <SideBarIcon icon={<FaUserCircle size="24" />} text='✨ Intro' />
        </button>     
   
       
        <button type="submit" onClick={() => {handleClickSection(props.expRef); }}>    
        <SideBarIcon icon={<MdWork size="24" />} text='👩🏻‍💻 Experience ' />
        </button>  

        <button type="submit" onClick={() => {handleClickSection(props.eduRef); }}>    
        <SideBarIcon icon={<FaUserGraduate size="24" />} text='📚 Edu' />
        </button>  


        {/* <button type="submit" onClick={() => {handleClickSection(props.certsRef); }}>    
        <SideBarIcon icon={<FaCertificate size="24" />} text='🎖️ Certifications 🎖️' />
        </button>   */}

        {/* <button type="submit" onClick={() => {handleClickSection(props.curriculumRef);  }}>    
        <SideBarIcon icon={<FaBook size="24" />} text='✏️ Expertise ✏️' />
        </button>   */}

        <button type="submit" onClick={() => {handleClickSection(props.skillsRef);  }}>    
        <SideBarIcon icon={<FaPencilRuler size="24" />} text='✏️ Skills' />
        </button>  

        {/* <button type="submit" onClick={() => {handleClickSection(props.projectsRef);  }}>    
        <SideBarIcon icon={<FaFlipboard size="24" />} text='💻 Projects 💻' />
        </button>   */}
        {/* <Divider /> */}
        
    </div>

    <div>
    <button
          className="flex items-center cursor-pointer fixed right-6 bottom-4 z-[80] hover:text-white bg-yellow-100 dark:bg-accent dark:bg-opacity-70 bg-opacity-40 custom-text-color hover:animate-pulse"
          onClick={() => window.scrollTo({top:0, left:0, behavior: 'smooth'})}
        >
          <FaArrowUp size={24} className="mx-2 my-2" />
        </button>
    </div>
    </div>
    <div className={`z-[60] fixed top-0 left-0 h-screen w-full flex flex-col pt-10 pl-15
    bg-gray-800 bg-opacity-60  ease-in-out duration-300 ${
      showSidebar ? "block" : " hidden"}`}>
    </div>
    </div>
  );
};

const SideBarIcon = ({ icon, text }) => (
  <div className="sidebar-icon group">
    {icon}
    <span class="sidebar-tooltip group-hover:scale-100">
      {text}
    </span>
  </div>
);

// const Divider = () => <hr className="sidebar-hr" />;

export default SideBar;