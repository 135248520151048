// import dragonhacks2 from '../../img/dragonhacks2.jpg'
// import dragonlogo from '../../img/dragonlogo.png'
// import HKN2 from '../../img/hkn2.png'
// import mlh3 from '../../img/mlh3.jpeg'
// import phillycodefest from '../../img/phillycodefest.jpg'

const Footer = () => {
    return (
        <div>
            <footer className="footer footer-center p-3 bg-black text-gray-300 bg-opacity-100 bg-custom-important">

                <div>
                <p> © {new Date().getFullYear()} - qtvo.dev - All rights reserved.</p>
                </div>

                {/* <div className="flex flex-row logo-style z-[35] justify-center text-center">
                    
                    <img alt="mlh" src={mlh3} className="" />
                    <img alt="dragonhacks" src={dragonhacks2} className="" />
                    <img alt="phillycodefest" src={phillycodefest} className="" />
                    <img alt="HKN" src={HKN2} className="ml-5" />
                    <img alt="dragonlogo" src={dragonlogo} className="" />
                </div> */}

            </footer>

            
           

        </div> 
        
    );
};

export default Footer;