import { useState } from "react";
import { motion } from "framer-motion";
import React from "react";

export default function HoverBoxes() {
  const [activeBox, setActiveBox] = useState(null);

  const handleMouseEnter = (index) => {
    setActiveBox(index);
  };

  const handleMouseLeave = () => {
    setActiveBox(null);
  };

  const boxContents = [
    <div>
      <h2 className="text-white text-lg font-medium pt-5 pl-5 pr-5 pb-5">Proficient:</h2>
      <p className="text-gray-300 text-sm pt-5 pl-5 pr-5 pb-5">Python, C, Javascript, Bash, VHDL</p>
    </div>,
    <div>
      <h2 className="text-white text-lg font-medium pt-5 pl-5 pr-5 pb-5">Familiar:</h2>
      <p className="text-gray-300 text-sm pt-5 pl-5 pr-5 pb-5">C++, C#, Java, Go, MATLAB, MySQL, CSS, Verilog, VLSI, Arduino, TI-MSP432, Lua, RISC-V</p>
    </div>,
    <div>
      <h2 className="text-white text-lg font-medium pt-5 pl-5 pr-5 pb-5">Software Engineering Stack:</h2>
      <p className="text-gray-300 text-sm pt-5 pl-5 pr-5 pb-5">AWS, GCP, React, Node.js, Express.js, Next.js, Git, Docker, Flask, Streamlit, MongoDB, PostgresQL, Prometheus, Grafana, Kubernetes, Terraform, GitLab, GitHub, Bitbucket, Jira, Docker, Jenkins, Helm, Backstage.io, Fluent-bit, Artifactory</p>
    </div>,
    <div>
      <h2 className="text-white text-lg font-medium pt-5 pl-5 pr-5 pb-5">Machine Learning:</h2>
      <p className="text-gray-300 text-sm pt-5 pl-5 pr-5 pb-5">PyTorch, TensorFlow, Scikit-learn, Keras, Reinforcement Learning</p>
    </div>,
    <div>
      <h2 className="text-white text-lg font-medium pt-5 pl-5 pr-5 pb-5">Operating System:</h2>
      <p className="text-gray-300 text-sm pt-5 pl-5 pr-5 pb-5">Windows, Linux, MacOS</p>
    </div>,
    <div>
      <h2 className="text-white text-lg font-medium pt-5 pl-5 pr-5 pb-5">Software & Tools:</h2>
      <p className="text-gray-300 text-sm pt-5 pl-5 pr-5 pb-5">Maple, VSCode, Fritzing, ModelSim, CCStudio, Vivado, Multisim, TinkerCAD</p>
    </div>,
  ];

  return (
    <div className="flex flex-col items-center justify-center min-h-screen  bg-black opacity-70">
      {[0, 1].map((row) => (
        <div key={row} className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6 mb-6">
          {[0, 1, 2].map((col) => {
            const index = row * 3 + col;
            return (
              <motion.div
                key={col}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                className="relative w-full md:w-[385px] h-[250px] bg-zinc-900 text-white rounded-xl flex items-start justify-start p-4 border border-gray-700"
              >
                {activeBox === index && (
                  <motion.div
                    className="absolute inset-0 rounded-xl"
                    style={{
                      background: "linear-gradient(145deg, rgba(255,255,255,0.1) 0%, transparent 70%)",
                      boxShadow: "0 0 20px rgba(255, 255, 255, 0.2)",
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ type: "spring", stiffness: 100, damping: 10 }}
                  />
                )}
                <div className="text-left">
                  {boxContents[index]}
                </div>
              </motion.div>
            );
          })}
        </div>
      ))}
    </div>
  );
}