import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

function handleScroll() {
  const specialElementBottom = document.querySelector('.special-fly-from-bottom');
  const specialElementRight = document.querySelector('.special-fly-from-right');
  const specialElementLeft = document.querySelector('.special-fly-from-left');
  const specialElementRight2 = document.querySelector('.special-fly-from-right-2');

  // Handle special element visibility for bottom
  if (specialElementBottom) {
    const rect = specialElementBottom.getBoundingClientRect();
    const inView = rect.top >= 0 && rect.bottom <= window.innerHeight;

    if (inView) {
      specialElementBottom.classList.add('in-view');
      specialElementBottom.classList.remove('out-of-view');
    } else {
      specialElementBottom.classList.add('out-of-view');
      specialElementBottom.classList.remove('in-view');
    }
  }

  // Handle special element visibility for right
  if (specialElementRight) {
    const rect2 = specialElementRight.getBoundingClientRect();
    const inView2 = rect2.top >= 0 && rect2.bottom <= window.innerHeight;

    if (inView2) {
      specialElementRight.classList.add('in-view');
      specialElementRight.classList.remove('out-of-view');
    } else {
      specialElementRight.classList.add('out-of-view');
      specialElementRight.classList.remove('in-view');
    }
  }

  // Handle special element visibility for left
  if (specialElementLeft) {
    const rect3 = specialElementLeft.getBoundingClientRect();
    const inView3 = rect3.top >= 0 && rect3.bottom <= window.innerHeight;

    if (inView3) {
      specialElementLeft.classList.add('in-view');
      specialElementLeft.classList.remove('out-of-view');
    } else {
      specialElementLeft.classList.add('out-of-view');
      specialElementLeft.classList.remove('in-view');
    }
  }

  // Handle special element visibility for right 2
  if (specialElementRight2) {
    const rect4 = specialElementRight2.getBoundingClientRect();
    const inView4 = rect4.top >= 0 && rect4.bottom <= window.innerHeight;

    if (inView4) {
      specialElementRight2.classList.add('in-view');
      specialElementRight2.classList.remove('out-of-view');
    } else {
      specialElementRight2.classList.add('out-of-view');
      specialElementRight2.classList.remove('in-view');
    }
  }
}

// Add scroll event listener
window.addEventListener('scroll', handleScroll);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();